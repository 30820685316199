<template>
    <div class="modal-card" ref="element">
  
      <header class="modal-card-head">
        <p class="modal-card-title">Full data</p>
        <p v-if="stD !== endD">{{stD}} - {{endD}}</p>
        <p v-else>{{stD}}</p>
      </header>
  
      <section class="modal-card-body" >
        <!--<b-table
            :data="fullData"
            :bordered="isBordered"
            :striped="isStriped"
            :narrowed="isNarrowed"
            :hoverable="isHoverable"
            :loading="isLoading"
            :focusable="isFocusable"
            :mobile-cards="hasMobileCards">

            <b-table-column field="id" label="ID" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props">
                {{ props.row.day }}
            </b-table-column>

            <b-table-column field="first_name" label="No. of messages" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.sum }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>-->
        <b-table :data="fullData" :columns="columns" :bordered="isBordered" class="equalWidth"></b-table>
       </section>
      <footer class="modal-card-foot">
        <b-button type="is-light" @click="closeModal()">Close</b-button>
      </footer>
  
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'FullData',
    props: [ 'fullData', 'stD', 'endD'],
    data: () => {
      return {
        isBordered: true,
        columns: [
            {
                field: 'day',
                label: 'Date',
                width: '300',
                centered: true
            },
            {
                field: 'sum',
                label: 'No. of messages',
                centered: true
            }
        ]
      }
    },
    created: function () {
    },
    methods: {
      closeModal () {
        this.$parent.close()
      }
    }
  }
  </script>

<style>
.foo > .label { color: white }
.equalWidth {
    width: 100%;
    display: table;
    table-layout: fixed; /* optional, for equal spacing */
    border-collapse: collapse;
}
</style>
  
<template>
  <section>
        <div class="container.is-widescreen" style="padding:18px">
<div>
  <h1 class="title" style="color:#3e8ed0">Welcome</h1>
  <h4 class="subtitle">Welcome to the SMS gateway system for Virtual SC.</h4>
<!--<img src="@/assets/eSMSlogo.png" alt="">-->
 </div>
  </div>
  </section>
</template>

<script>
  // import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'HomeView'
  }
</script>

<template>
    <section>
      <div class="container.is-widescreen" style="padding-top:18px">
        <div v-if="this.getRole !== 'User' && this.getRole !== 'Analytics'">
            <h1 class="title" style="color:#3e8ed0">Create user</h1>
            <h4 class="subtitle">Add a new user.</h4>

                    <div class="container">
  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Username:">
            <b-input v-model="username"></b-input>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Password:">
            <b-input v-model="password" type="password" password-reveal></b-input>
        </b-field>
    </div>
    <div class="col" v-if="getRole === 'Super Admin'">
        <b-field label="Role:">
      <b-select v-model="role" expanded>
              <option v-for="(value, key) in roles" :key="key" :value="value">
                {{ value }}
              </option>
            </b-select>
    </b-field>
    </div>
    <div class="col" v-if="getRole === 'Super Admin' && showWorkspace">
        <b-field label="Workspace:">
          <b-select v-model="workspaceId" expanded>
              <option v-for="(value, key) in allWorkspaces" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Email:">
            <b-input v-model="email"></b-input>
        </b-field>
    </div>
  </div>

  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Name:">
            <b-input v-model="name"></b-input>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Surname:">
            <b-input v-model="surname"></b-input>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Address:">
            <b-input v-model="address"></b-input>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Phone:">
            <b-input v-model="phone"></b-input>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Status">
          <b-select v-model="status" expanded>
            <option v-for="(value, key) in statuses" :key="key" :value="value">
                {{ value }}
              </option>
            </b-select>
        </b-field>
    </div>
  </div>
  
  <div style="text-align: center;">
  <b-button type="is-primary" @click="addNewUser">Submit</b-button>
</div>
</div>
</div>
</div>
    </section>
  </template>
  
<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'CreateUser',
    computed: mapGetters(['roles', 'statuses', 'allWorkspaces', 'getRole', 'getWorkspaceId']),
    created: function () {
      if (this.getRole !== 'Analytics' && this.getRole !== 'User') {
        this.fetchStatuses()
        if (this.getRole === 'Super Admin') {
          this.fetchWorkspaces()
          this.fetchRoles()
        }
      }
    },
    mounted: function () {
      if (this.getRole === 'Admin') {
        this.workspaceId = this.getWorkspaceId
      }
    },
    data () {
      return {
        username: null,
        password: null,
        email: null,
        role: null,
        name: null,
        surname: null,
        address: null,
        phone: null,
        status: null,
        workspaceId: null,
        showWorkspace: true
      }
    },
    watch: {
      role (newValue) {
        if (newValue === 'Super Admin') {
          this.showWorkspace = false
        } else {
          this.showWorkspace = true
        }
      }
    },
    methods: {
      ...mapActions(['addUser', 'fetchRoles', 'fetchStatuses', 'fetchWorkspaces']),
      async addNewUser () {
        if (this.getRole === 'Super Admin' && this.checkSuperAdmin()) {
            this.showAlert()
        } else if (this.getRole === 'Admin' && this.checkAdmin()) {
          this.showAlert()
        } else {
          let payload = {
            username: this.username,
            password: this.password,
            email: this.email,
            // role: this.role,
            name: this.name,
            surname: this.surname,
            address: this.address,
            phone: this.phone,
            status: this.status,
            workspaceId: this.workspaceId
          }
          if (this.getRole === 'Super Admin') {
            payload.role = this.role
            if (this.role === 'Super Admin') {
              payload.workspaceId = '1'
            }
          } else {
            payload.role = 'User'
          }
          await this.addUser(payload)
          this.reset()
        }
      },
      reset () {
        this.username = null
        this.password = null
        this.email = null
        this.role = null
        this.name = null
        this.surname = null
        this.address = null
        this.phone = null
        this.status = null
        this.workspaceId = null
      },
      showAlert () {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Please fill in all fields.',
          confirmText: 'Ok!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa'
        })
      },
      checkSuperAdmin () {
        return this.username === null || this.username.trim() === '' || this.password === null || this.surname === null
          || this.password.trim() === '' || this.role === null || (this.workspaceId === null && this.role !== 'Super Admin')
          || this.email === null || this.email.trim() === '' || this.name === null || this.name.trim() === '' || this.address === null
          || this.address.trim() === '' || this.phone === null || this.phone.trim() === '' || this.status === null || this.surname.trim() === ''
      },
      checkAdmin () {
        return this.username === null || this.username.trim() === '' || this.password === null || this.surname === null || this.surname.trim() === ''
          || this.password.trim() === '' || this.email === null || this.email.trim() === '' || this.name === null || this.name.trim() === ''
          || this.address === null || this.address.trim() === '' || this.phone === null || this.phone.trim() === '' || this.status === null
      }
    }
  }
</script>

<style>
</style>
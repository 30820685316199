<template>
    <section>
      <div v-if="getRole !== 'Analytics'" class="container.is-widescreen" style="padding-top:18px">

            <h1 class="title" style="color:#3e8ed0">Send single SMS</h1>
            <h4 class="subtitle">Send a message to a user.</h4>

                    <div class="container">
                        <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Project:">
          <b-select v-model="projectId" expanded>
              <option v-for="(value, key) in allProjects" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Receiver:">
          <b-input v-model="receiver" placeholder="Example: '3897xxxxxxx'"></b-input>
        </b-field>
    </div>
  </div>

  <div style="text-align: center;">

        <b-field label="Message text:">
            
        <b-input v-model="messageText" type="textarea" 
        :maxlength="maxLength"></b-input>
        </b-field>
</div>

<div style="text-align: center; padding-bottom: 10px; color: red">
<p><b>{{ countString }} {{ noMessagesString }}</b></p>
</div>
  
  <div style="text-align: center;">
  <b-button type="is-primary" @click="addMess">Submit</b-button>
</div>
</div>
</div>
    </section>
  </template>
  
<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'SendMessage',
    computed: mapGetters(['allProjects', 'getRole', 'getWorkspaceId']),
    created: function () {
      /* if (this.getRole !== 'Super Admin') {
        this.fetchSendProjectsByWorkspaceId(this.getWorkspaceId)
      } else {
        this.fetchSendProjects()
      } */

      if (this.getRole === 'Super Admin') {
        this.fetchSendProjects()
      } else if (this.getRole !== 'Analytics') {
        this.fetchSendProjectsByWorkspaceId(this.getWorkspaceId)
      }
    },
    data () {
      return {
        messageText: '',
        receiver: null,
        projectId: null,
        count: null,
        noMessages: 0,
        noMessagesString: null,
        countString: null,
        maxLength: 612,
        digitsArray: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      }
    },
    watch: {
      messageText () {
        this.maxLength = 612
        var extraChars = 0
        if (this.messageText) {
          for (var i = 0; i < this.messageText.length; i++) {
            var c = this.messageText[i]
            if (c === '^' || c === '{' || c === '}' || c === '\\' || c === '[' || c === ']' || c === '~' || c === '|' || c === '€') {
              extraChars += 1
            }
            // let result = c.charCodeAt(0);
            // let hex = result.toString(16);
            // console.log('hex: ', hex)
          }
          this.maxLength -= extraChars
          this.count = this.messageText.length + extraChars
          if (this.count > 1) {
            this.countString = this.count + ' characters,'
          } else {
            this.countString = '1 character,'
          }
          if (this.count === 0) {
            this.noMessages = 0
            this.noMessagesString = ''
            this.countString = ''
          } else if (this.count <= 160 ) {
            this.noMessages = 1
            this.noMessagesString = '1 message'
          } else if (this.count > 160 && this.count <= 320) {
            this.noMessages = 2
            this.noMessagesString = '2 messages'
          } else if (this.count > 320 && this.count <= 480) {
            this.noMessages = 3
            this.noMessagesString = '3 messages'
          } else {
            this.noMessages = 4
            this.noMessagesString = '4 messages'
          }
        } else {
          this.countString = null
          this.noMessagesString = null
        }
      }
    },
    methods: {
      ...mapActions(['fetchSendProjects', 'addSingleMessage', 'fetchSendProjectsByWorkspaceId']),
      async addMess () {
        if (this.receiver === null || this.receiver === '' || this.projectId === null || this.messageText === '') {
          this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Please fill in all fields.',
              confirmText: 'Ok!',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa'
          })
        } /* else if (!this.isValidNumber(this.receiver)) {
          this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Invalid receiver. Required format: "3897xxxxxxx"',
              confirmText: 'Ok!',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa'
          })
        } */ else {
          let payload = {
            text: this.messageText,
            receiver: [this.receiver],
            projectId: this.projectId,
            size: this.noMessages
          }
          await this.addSingleMessage(payload)
          this.reset()
        }
      },
      reset () {
        this.messageText = null
        this.receiver = null
        this.projectId = null
        this.countString = null
        this.noMessagesString = null
      },
      isValidNumber (receiver) {
        if (receiver.length === 11 && receiver[0] === '3' && receiver[1] === '8' && receiver[2] === '9' && receiver[3] === '7') {
          var flag = true
          for (let i = 4; i < 11; i++) {
            if (!this.digitsArray.includes(receiver[i])) {
              flag = false
            }
          }
          return flag
        } else {
          return false
        }
      }
    }
  }
</script>

<style>
</style>
<template>
    <section>
      <div v-if="getRole !== 'Analytics'" class="container.is-widescreen" style="padding-top:18px">
        <div>

            <h1 class="title" style="color:#3e8ed0">All users</h1>
            <h4 class="subtitle">See and edit existing users.</h4>
            <div class="col" v-if="getRole === 'Super Admin'" style="width: 18%; padding-bottom: 10px">
        <b-field label="Workspace:">
          <b-select v-model="workspaceId" expanded>
            <option value="all">All</option>
              <option v-for="(value, key) in allWorkspaces" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
            <div style="padding-right: 20px;">
    <b-table :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :pagination-order="paginationOrder"
            :mobile-cards="false" :data="allUsers.length > 0 ? allUsers : []" striped>

          <!-- <b-table-column field="number" centered  v-slot="props">
            {{parseInt(props.row.id)+1}}
          </b-table-column> -->
          <b-table-column field="username" label="Username" centered  v-slot="props">
            {{props.row.username}}
          </b-table-column>
          <b-table-column field="email" label="Email" centered v-slot="props">
            {{props.row.email}}
          </b-table-column>
          <b-table-column v-if="getRole === 'Super Admin'" field="workspace" label="Workspace" centered v-slot="props">
            {{getWorkspaceName(props.row.workspaceId)}}
          </b-table-column>
          <b-table-column field="role" label="Role" centered  v-slot="props">
            <!--{{roles[props.row.role]}}-->
            {{ props.row.role }}
          </b-table-column>
          <b-table-column field="status" label="Status" centered  v-slot="props">
            {{ props.row.status }}
          </b-table-column>

          <b-table-column label="Edit" centered v-slot="props">
            <!-- <a style="color: #6d70f2" @click="editCat(props.row)">Edit</a>-->
            <div style="cursor: pointer" @click="editUsr(props.row)"><img src="../../assets/edit.png" 
            width="25"/></div>
          </b-table-column>
          <b-table-column label="Delete" centered v-slot="props">
            <div :style="getRole === 'User' ? 'cursor: not-allowed' : 'cursor: pointer'" @click="delUsr(props.row._id, props.row.username)"><img src="../../assets/delete.png" 
            width="25"/></div>
          </b-table-column>

      </b-table>

</div>
        </div>

</div>
    </section>
  </template>
    <!--</div>
    </div>
    </section>
  </template>-->
  
<script>
import { mapGetters, mapActions } from 'vuex'
import EditUser from '@/components/EditUser.vue'
  
  export default {
    name: 'AllUsers',
    computed: mapGetters(['allUsers', 'roles', 'statuses', 'allWorkspaces', 'getRole', 'getWorkspaceId']),
    created() {
      if (this.getRole !== 'Analytics') {
        if (this.getRole !== 'Super Admin') {
          this.fetchUsersByWorkspaceId(this.getWorkspaceId)
        } else {
          this.fetchWorkspaces()
          this.fetchUsers()
        }
        this.fetchRoles()
        this.fetchStatuses()
      }
    },
    data () {
      return {
        workspaceId: 'all',
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        currentPage: 1,
        perPage: 10,
        paginationOrder: '',
      }
    },
    watch: {
      async workspaceId(newValue) {
        var loadingComponent = this.$buefy.loading.open()
        if (newValue !== 'all') {
          await this.fetchUsersByWorkspaceId(newValue)
          loadingComponent.close()
        } else {
          await this.fetchUsers()
          loadingComponent.close()
        }
      }
    },
    methods: {
      ...mapActions(['fetchUsers', 'deleteUser', 'fetchRoles', 'fetchStatuses', 'fetchWorkspaces', 'fetchUsersByWorkspaceId']),
      editUsr(usr) {
        this.$buefy.modal.open({
          component: EditUser,
          parent: this,
          props: { userToEdit: usr },
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['x']
        })
      },
      delUsr(id, username) {
        if (this.getRole !== 'User') {
          this.$buefy.dialog.confirm({
            message: 'Are you sure that you want to <b>delete</b> user "' + username + '"?',
            confirmText: 'Yes',
            type: 'is-danger',
            onConfirm: () => {
              this.deleteUser(id)
            }
          })
        }
      },
      getWorkspaceName (id) {
        for (let i = 0; i < this.allWorkspaces.length; i++) {
          if (this.allWorkspaces[i]['_id'] === id) {
            return this.allWorkspaces[i].name;
          }
        }
        return '/'
      }
    }
  }
</script>

<style>
</style>
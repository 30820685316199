<template>
    <section>
      <div class="container.is-widescreen" style="padding:18px">

            <h1 class="title" style="color:#3e8ed0">Activity</h1>
            <h4 class="subtitle">No. of sent messages by hours in a day.</h4>
          
          <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">
              <b-field label="Project:">
                <b-select v-model="projectId" expanded>
              <option v-for="(value, key) in showProjects" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
    </b-field>
  </div>
      
<div style="padding-right: 20px;">
            <b-field label="Date:">
                <b-datepicker
                v-model="selected"
                :show-week-number="showWeekNumber"
                :locale="locale"
                placeholder="Click to select..."
                icon="calendar-today"
                :icon-right="selected ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDate"
                trap-focus>
            </b-datepicker>
    </b-field>
  </div>
  <div style="color: white">
 <b-field label="/" class="foo"><b-button type="is-primary" @click="searchClicked">Search</b-button></b-field>
</div>
  
        </div>
        <b-table v-if="showData" :data="data" :columns="columns" :bordered="isBordered"></b-table>
        <div style="width: 100%;">
        <GChart
        v-if="showData"
    type="LineChart"
    :data="chartData"
    :options="chartOptions"
  />
  <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">
              <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                v-if="showData"
                    label="Export data"
                    type="is-info"
                    icon-right="menu-down" />
            </template>

            <b-dropdown-item aria-role="listitem">
              <download-excel v-if="showData" :data="data" name="AnalyticsByHoursInDay.csv" type="csv" :escapeCsv="falseString">
                CSV
              </download-excel>
              </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              <download-excel v-if="showData" :data="data" name="AnalyticsByHoursInDay.xls">
              Excel
            </download-excel>
            </b-dropdown-item>
        </b-dropdown>
            </div>
            </div>
</div>
  </div>
    </section>
  </template>
    <!--</div>
    </div>
    </section>
  </template>-->
  
<script>

import { GChart } from 'vue-google-charts/legacy'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
// import { json } from 'body-parser'
  
  export default {
    name: 'HoursDay',
    computed: mapGetters(['allProjects', 'getAuthHeader', 'getRole', 'getWorkspaceId']),
    created: function () {
      if (this.getRole !== 'Super Admin') {
        this.fetchProjectsByWorkspaceId(this.getWorkspaceId)
      } else {
        this.fetchProjects()
      }
      this.showProjects = this.allProjects
      this.showProjects = this.showProjects.filter(x => x.projectType === 'Send Messages' || x.projectType === 'Forward Messages')
    },
    data () {
      return {
        showProjects: [],
        showData: false,
        projectId: null,
        isBordered: true,
        selected: null,
        showWeekNumber: false,
        locale: 'en-GB',
        pjct: null,
        falseString: false,
        // Array will be automatically processed with visualization.arrayToDataTable function
        chartData: [
          ['Hour', 'No. of messages']
        ],
        hoursArray: ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21', '21-22', '22-23', '23-00'],
        chartOptions: {
          height: 300,
          fontName: 'Nunito',
          fontSize: '14',
          title: '',
          // title: 'Dongle Viber Test, 3/21/2022 - 3/27/2022',
          vAxis: {
            format: '0'
          }
        },
        data: [],
        columns: [
            {
                field: '00-01',
                label: '00-01',
                // width: '40',
                // numeric: true
                centered: true
            },
            {
                field: '01-02',
                label: '01-02',
                centered: true
            },
            {
                field: '02-03',
                label: '02-03',
                centered: true
            },
            {
                field: '03-04',
                label: '03-04',
                centered: true
            },
            {
                field: '04-05',
                label: '04-05',
                centered: true
            },
            {
                field: '05-06',
                label: '05-06',
                centered: true
            },
            {
                field: '06-07',
                label: '06-07',
                centered: true
            },
            {
                field: '07-08',
                label: '07-08',
                centered: true
            },
            {
                field: '08-09',
                label: '08-09',
                centered: true
            },
            {
                field: '09-10',
                label: '09-10',
                centered: true
            },
            {
                field: '10-11',
                label: '10-11',
                centered: true
            },
            {
                field: '11-12',
                label: '11-12',
                centered: true
            },
            {
                field: '12-13',
                label: '12-13',
                centered: true
            },
            {
                field: '13-14',
                label: '13-14',
                centered: true
            },
            {
                field: '14-15',
                label: '14-15',
                centered: true
            },
            {
                field: '15-16',
                label: '15-16',
                centered: true
            },
            {
                field: '16-17',
                label: '16-17',
                centered: true
            },
            {
                field: '17-18',
                label: '17-18',
                centered: true
            },
            {
                field: '18-19',
                label: '18-19',
                centered: true
            },
            {
                field: '19-20',
                label: '19-20',
                centered: true
            },
            {
                field: '20-21',
                label: '20-21',
                centered: true
            },
            {
                field: '21-22',
                label: '21-22',
                centered: true
            },
            {
                field: '22-23',
                label: '22-23',
                centered: true
            },
            {
                field: '23-00',
                label: '23-00',
                centered: true
            },
            {
                field: 'Total',
                label: 'Total',
                centered: true
            }
        ]
      }
    },
    components: {
      GChart
    },
    methods: {
      ...mapActions(['fetchProjects', 'fetchProjectsByWorkspaceId']),
        clearDate () {
            this.selected = null
        },
        async searchClicked () {
          if (this.selected === null || this.projectId === null) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Please choose both project and a date.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
          } else {
            var loadingComponent = this.$buefy.loading.open()
            let dateOriginal = this.selected.toLocaleDateString('en-GB')
            var dateArray = dateOriginal.split('/')
            var date = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0]
            try {
              const response = await axios.post('https://smsgwnc.vsc.mk:8080/analytics/hoursByDay/' + this.projectId, {
                day: date
              }, {
                  headers: { 'Authorization': this.getAuthHeader }
              })
              loadingComponent.close()
              this.convertToJson(response.data)
              this.showData = true
            } catch (error) {
              loadingComponent.close()
              this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'An error occured while trying to fetch data.',
                  confirmText: 'Ok!',
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa'
              })
            }
          }
        },
        convertToJson (data) {
          this.chartData = [
            ['Hour', 'No. of messages']
          ]
          var jsonObj = {}
          var total = 0
          for (var i = 0 ; i < data.length; i++) {
              jsonObj[this.hoursArray[i]] = data[i]
              total += data[i]
          }
          for (let item in jsonObj) {
            this.chartData.push([item, jsonObj[item]])
          }
          jsonObj['Total'] = total
          this.data = [jsonObj]
        }
    }
  }
</script>

<style>
.foo > .label { color: white }
</style>
<template>
    <section>
      <div class="container.is-widescreen" style="padding-top:18px">
        <div v-if="this.getRole === 'Super Admin'">
            <h1 class="title" style="color:#3e8ed0">All workspaces</h1>
            <h4 class="subtitle">See and edit existing workspaces.</h4>
          
            <div style="width: 50%;">
    <b-table :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :pagination-order="paginationOrder"
            :mobile-cards="false" :data="allWorkspaces.length > 0 ? allWorkspaces : []" striped>

          <b-table-column field="name" label="Name" centered  v-slot="props">
            {{props.row.name}}
          </b-table-column>

          <b-table-column label="Edit" centered v-slot="props">
            <!-- <a style="color: #6d70f2" @click="editCat(props.row)">Edit</a>-->
            <div style="cursor: pointer" @click="editWrks(props.row)"><img src="../../assets/edit.png" 
            width="25"/></div>
          </b-table-column>
          <b-table-column label="Delete" centered v-slot="props">
            <div style="cursor: pointer" @click="delWrks(props.row._id, props.row.name)"><img src="../../assets/delete.png" 
            width="25"/></div>
          </b-table-column>
      </b-table>

</div>
        </div>
</div>

    </section>
  </template>
  
<script>
import { mapGetters, mapActions } from 'vuex'
import EditWorkspace from '@/components/EditWorkspace.vue'
  
  export default {
    name: 'AllWorkspaces',
    computed: mapGetters(['allWorkspaces', 'getRole', 'allProjects']),
    created() {
      if (this.getRole === 'Super Admin') {
        this.fetchWorkspaces()
      }
    },
    mounted: function () {
    },
    data () {
      return {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        currentPage: 1,
        perPage: 10,
        paginationOrder: '',
      }
    },
    methods: {
      ...mapActions(['fetchWorkspaces', 'deleteWorkspace', 'fetchProjectsByWorkspaceId']),
      editWrks(wrx) {
        this.$buefy.modal.open({
          component: EditWorkspace,
          parent: this,
          props: { workspaceToEdit: wrx },
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['x']
        })
      },
      delWrks(id, name) {
        this.fetchProjectsByWorkspaceId(id) // for check
        this.$buefy.dialog.confirm({
          message: 'Are you sure that you want to <b>delete</b> workspace "' + name + '"?',
          confirmText: 'Yes',
          type: 'is-danger',
          onConfirm: () => {
            if (this.allProjects.length > 0) {
              this.showAlert()
            } else {
              this.deleteWorkspace(id)
            }
          }
        })
      },
      showAlert () {
        this.$buefy.dialog.alert({
          message: 'Workspace cannot be deleted because there are projects associated with it.',
          confirmText: 'Ok!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa'
        })
      }
    }
  }
</script>

<style>
</style>
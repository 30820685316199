<template>
    <section>
      <div class="container.is-widescreen" style="padding:18px">

            <h1 class="title" style="color:#3e8ed0">Activity</h1>
            <h4 class="subtitle">No. of sent messages by days in a range.</h4>
          
          <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">
              <b-field label="Project:">
                <b-select v-model="projectId" expanded>
              <option v-for="(value, key) in showProjects" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
    </b-field>
  </div>
<div style="padding-right: 20px;">
            <b-field label="Date range:">
                <b-datepicker
                v-model="dates"
                :locale="locale"
                icon="calendar-today"
                placeholder="Click to select..."
                range>
            </b-datepicker>
    </b-field>
  </div>
  <div style="color: white">
 <b-field label="/" class="foo"><b-button type="is-primary" @click="searchClicked">Search</b-button></b-field>
</div>
  
        </div>
       <GChart
        v-if="showData"
    type="LineChart"
    :data="chartData"
    :options="chartOptions"
  />
        <div >
        
  <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">
  <div style="padding-right: 20px">
    <b-button v-if="showData" @click="viewAllData" type="is-primary">View full data</b-button>
  </div>
              <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                v-if="showData"
                    label="Export data"
                    type="is-info"
                    icon-right="menu-down" />
            </template>

            <b-dropdown-item aria-role="listitem">
              <download-excel v-if="showData" :data="tableData" :fields="jsonFields" name="AnalyticsByDaysInRange.csv" type="csv" :escapeCsv="falseString">
                CSV
              </download-excel>
              </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              <download-excel v-if="showData" :data="tableData" :fields="jsonFields" name="AnalyticsByDaysInRange.xls">
              Excel
            </download-excel>
            </b-dropdown-item>
        </b-dropdown>
            </div>
            </div>
         
  </div>
    </section>
  </template>
    <!--</div>
    </div>
    </section>
  </template>-->
  
<script>

import { GChart } from 'vue-google-charts/legacy'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import FullData from '@/components/FullData'
  
  export default {
    name: 'DaysRange',
    computed: mapGetters(['allProjects', 'getRole', 'getWorkspaceId', 'getAuthHeader']),
    created: function () {
      if (this.getRole !== 'Super Admin') {
        this.fetchProjectsByWorkspaceId(this.getWorkspaceId)
      } else {
        this.fetchProjects()
      }
      this.showProjects = this.allProjects
      this.showProjects = this.showProjects.filter(x => x.projectType === 'Send Messages' || x.projectType === 'Forward Messages')
    },
    data () {
      return {
        showProjects: [],
        sum: 0,
        tableData: null,
        showData: false,
        projectId: null,
        dates: [],
        isBordered: true,
        falseString: false,
        locale: 'en-GB',
        pjct: null,
        // Array will be automatically processed with visualization.arrayToDataTable function
        chartData: [
          ['Day', 'No. of messages']
        ],
        chartOptions: {
          height: 300,
          fontName: 'Nunito',
          fontSize: '14',
          title: '',
          vAxis: {
            format: '0'
          }
        },
        jsonFields: {
          "Date": "day",
          "No. of messages": "sum"
        }
      }
    },
    components: {
      GChart
    },
    methods: {
      ...mapActions(['fetchProjects', 'fetchProjectsByWorkspaceId']),
        async searchClicked () {
          this.showData = false
          if (this.dates.length !== 2 || this.projectId === null) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Please choose both project and a date range.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
          } else {
            this.sum = 0
            this.chartData = [
              ['Day', 'No. of messages']
            ]
            var loadingComponent = this.$buefy.loading.open()
            let startDateOriginal = this.dates[0].toLocaleDateString('en-GB')
            let endDateOriginal = this.dates[1].toLocaleDateString('en-GB')
            var startDateArray = startDateOriginal.split('/')
            var endDateArray = endDateOriginal.split('/')
            var startDate = startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0]
            var endDate = endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0]
            try {
              const response = await axios.post('https://smsgwnc.vsc.mk:8080/analytics/daysRange/' + this.projectId, {
                startDate: startDate,
                endDate: endDate
              }, {
                  headers: { 'Authorization': this.getAuthHeader }
              })
              loadingComponent.close()
              this.tableData = response.data
              for (let obj in response.data) {
                this.chartData.push([response.data[obj].day.substring(0, 10), response.data[obj].sum])
                this.tableData[obj].day = this.tableData[obj].day.substring(0, 10)
                this.sum += this.tableData[obj].sum
              }
              this.showData = true
            } catch (error) {
              loadingComponent.close()
              this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'An error occured while trying to fetch data.',
                  confirmText: 'Ok!',
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa'
              })
            }
          }
        },
        viewAllData () {
          var startD = this.dates[0].toLocaleDateString('en-GB')
          var endD = this.dates[1].toLocaleDateString('en-GB')
          var fullData = this.tableData
          if (fullData[fullData.length - 1].day !== '<b>Total</b>') {
            fullData.push({
              day: '<b>Total</b>',
              sum: this.sum
            })
          }
          this.$buefy.modal.open({
            parent: this,
            component: FullData,
            props: { fullData: fullData, stD: startD, endD: endD },
            hasModalCard: true,
            trapFocus: true
            // onCancel: this.cancelAdd
          })
        }
    }
  }
</script>

<style>
.foo > .label { color: white }
.equalWidth {
    width: 100%;
    display: table;
    table-layout: fixed; /* optional, for equal spacing */
    border-collapse: collapse;
}
</style>
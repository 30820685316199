<template>
    <div class="navbar-end">
        <div class="navbar-item">
          
<p style="padding-right: 17px">Logged in as {{this.getUsername}}</p>
          <div class="buttons">
            <b-button @click="logout" type="is-light">Log out</b-button>
          </div>
        </div>
      </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NavBar',
  data() {
    return {
      shouldShow: false
    }
  },
  computed: mapGetters(['getUsername']),
  methods: {
    ...mapActions(['setUsername', 'setPassword', 'setAuthHeader', 'setRole', 'setWorkspaceId']),
    logout() {
      this.setUsername(null)
      this.setPassword(null)
      this.setAuthHeader(null)
      this.setRole(null)
      this.setWorkspaceId(null)
      localStorage.removeItem('loggedIn')
      this.$router.replace({ name: 'login' })
    }
  },
  mounted() {
    this.shouldShow = localStorage.getItem('loggedIn') ? true : false
    if (!this.shouldShow) {
      this.setUsername(null)
      this.setPassword(null)
      this.setAuthHeader(null)
      this.setRole(null)
      this.setWorkspaceId(null)
    }
  },
  watch: {
    shouldShow() {
      this.shouldShow = localStorage.getItem('loggedIn') ? true : false
      if (!this.shouldShow) {
        this.setUsername(null)
        this.setPassword(null)
        this.setAuthHeader(null)
        this.setRole(null)
        this.setWorkspaceId(null)
      }
    }
  }
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import projects from './modules/projects'
import workspaces from './modules/workspaces'
import messages from './modules/messages'
import data from './modules/data'
import user from './modules/user'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    users,
    projects,
    workspaces,
    messages,
    data,
    user
  },
  plugins: [createPersistedState({
    paths: ['user', 'data', 'projects']
  })]
})

<template>
      <v-list nav style="margin-top:0rem;background-color: #d5e3f0; border-radius: 5px; width: 251px;">

        <v-list-item 
        to="/" link>
          <v-list-item-icon>
            <v-icon style="margin-right: 15px;">home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
          <v-list-item-title style="font-size: 17px">Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
        v-if="getRole === 'User'"
          :value="false"
          prepend-icon="mdi-message-text"
        >
          <template v-slot:activator>
            <v-list-item-title style="padding-left: 14px;  font-size: 17px">Projects & users</v-list-item-title>
          </template>

          <v-list-item
              v-for="([title, icon, to], i) in adminsRegular"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title" style="padding-left: 26px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
        </v-list-group>
  
        <v-list-group
        v-if="getRole === 'Super Admin'"
          :value="false"
          prepend-icon="mdi-security"
        >
          <template v-slot:activator>
            <v-list-item-title style="padding-left: 14px;  font-size: 17px">Admin</v-list-item-title>
          </template>

          <v-list-item
              v-for="([title, icon, to], i) in admins"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title" style="padding-left: 26px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
  
          <v-list-group
            :value="false"
            no-action
            sub-group
            style="padding-left: 15px"
            prepend-icon="mdi-plus"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="padding-left: 8px; ">Create</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon, to], i) in admins1"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title"  style="padding-left: 30px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
  
          
        </v-list-group>


        <v-list-group
        v-if="getRole === 'Admin'"
          :value="false"
          prepend-icon="mdi-security"
        >
          <template v-slot:activator>
            <v-list-item-title style="padding-left: 14px;  font-size: 17px">Admin</v-list-item-title>
          </template>

          <v-list-item
              v-for="([title, icon, to], i) in adminsRegular"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title" style="padding-left: 26px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
  
          <v-list-group
            :value="false"
            no-action
            sub-group
            style="padding-left: 15px"
            prepend-icon="mdi-plus"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="padding-left: 8px; ">Create</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon, to], i) in adminsRegular1"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title"  style="padding-left: 30px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
  
          
        </v-list-group>

        <v-list-group
          v-if="getRole !== 'Analytics'"
          :value="false"
          prepend-icon="mdi-message-text"
        >
          <template v-slot:activator>
            <v-list-item-title style="padding-left: 14px;  font-size: 17px">Send message</v-list-item-title>
          </template>

          <v-list-item
              v-for="([title, icon, to], i) in sendmessage"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title" style="padding-left: 26px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          prepend-icon="mdi-google-analytics"
        >
          <template v-slot:activator>
            <v-list-item-title style="padding-left: 14px; font-size: 17px">Analytics</v-list-item-title>
          </template>

          <v-list-group
            :value="false"
            no-action
            sub-group
            style="padding-left: 15px"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="padding-left: 8px; ">Activity</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon, to], i) in activity"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title" style="padding-left: 30px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group
          v-if="getRole === 'Super Admin'"
            :value="false"
            no-action
            sub-group
            style="padding-left: 15px"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="padding-left: 8px; ">Stats</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon, to], i) in stats"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title"  style="padding-left: 30px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group
          v-else
            :value="false"
            no-action
            sub-group
            style="padding-left: 15px"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="padding-left: 8px; ">Stats</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon, to], i) in statsRegular"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title"  style="padding-left: 30px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group
          v-if="getRole === 'Super Admin'"
            :value="false"
            no-action
            sub-group
            style="padding-left: 15px"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="padding-left: 8px; ">Messages</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon, to], i) in messages"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title"  style="padding-left: 30px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group
          v-else
            :value="false"
            no-action
            sub-group
            style="padding-left: 15px"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="padding-left: 8px; ">Messages</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon, to], i) in messagesRegular"
              :key="i"
              :to="to"
              link
            >
              <v-list-item-title v-text="title"  style="padding-left: 30px; "></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
  
          
        </v-list-group>

      </v-list>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['getRole']),
  data: () => ({
    admins: [
      ['Workspaces', 'mdi-folder-multiple', '/allworkspaces'],
      ['Projects', 'mdi-checkbox-multiple-blank', '/allprojects'],
      ['Users', 'mdi-account-multiple-outline', '/allusers'],
    ],
    adminsRegular: [
      ['Projects', 'mdi-checkbox-multiple-blank', '/allprojects'],
      ['Users', 'mdi-account-multiple-outline', '/allusers'],
    ],
    admins1: [
      ['Workspace', 'mdi-folder-plus', '/createworkspace'],
      ['Project', 'mdi-plus-box', '/createproject'],
      ['User', 'mdi-account-plus', '/createuser']
    ],
    adminsRegular1: [
      ['User', 'mdi-account-plus', '/createuser']
    ],
    sendmessage: [
      ['Single', 'mdi-message-outline', '/sendmessage'],
      ['Bulk', 'mdi-message-fast-outline', '/sendmessages'],
    ],
    activity: [
      ['By hours', 'mdi-timer-sand-empty', '/hoursday'],
      ['By days', 'mdi-calendar-today-outline', '/daysrange']
    ],
    stats: [
      ['By type', 'mdi-swap-horizontal', '/bytype'],
      ['By status', 'mdi-message-question-outline', '/bystatus'],
      ['By provider', 'mdi-cellphone', '/byproviders']
    ],
    statsRegular: [
      ['By type', 'mdi-swap-horizontal', '/bytype'],
      ['By status', 'mdi-message-question-outline', '/bystatus']
    ],
    messages: [
      ['All', 'mdi-forum', '/allmessages'],
      ['Sent by project', 'mdi-call-made', '/sentmessagesbyproject'],
      ['Received by project', 'mdi-call-received', '/receivedmessagesbyproject']
    ],
    messagesRegular: [
      ['Sent by project', 'mdi-call-made', '/sentmessagesbyproject'],
      ['Received by project', 'mdi-call-received', '/receivedmessagesbyproject']
    ]
  }),
}
</script>
<template>
    <section>
      <div class="container.is-widescreen" style="padding:18px" v-if="getRole === 'Super Admin'">

            <h1 class="title" style="color:#3e8ed0">Analytics</h1>
            <h4 class="subtitle">No. of messages by providers.</h4>
          
          <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">

            <div style="padding-right: 20px;">
              <b-field label="Project:">
                <b-select v-model="projectId" expanded>
                  <option value="all">All</option>
              <option v-for="(value, key) in allProjects" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
    </b-field>
  </div>

  <div v-if="projectId === 'all'" style="padding-right: 20px;">
              <b-field label="Message type:">
                <b-select v-model="messageType" expanded>
                  <option value="sent">Sent & forwarded</option>
                  <option value="received">Received</option>
            </b-select>
    </b-field>
  </div>
      
<div style="padding-right: 20px;">
            <b-field label="Date range:">
                <b-datepicker
                v-model="dates"
                :locale="locale"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus range>
            </b-datepicker>
    </b-field>
  </div>
  <div style="color: white">
 <b-field label="/" class="foo"><b-button type="is-primary" @click="searchClicked">Search</b-button></b-field>
</div>
  
        </div>
        <div style="width: 40%;" v-if="showData">
        <b-table :data="tableData" :columns="columnsC" :bordered="isBordered" class="equalWidth"></b-table>
    </div>

        <div>
  <div class="columns" style="padding-left: 14px; padding-top: 30px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">
              <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                v-if="showData"
                    label="Export data"
                    type="is-info"
                    icon-right="menu-down" />
            </template>

            <b-dropdown-item aria-role="listitem">
              <download-excel v-if="showData" :data="tableData" :fields="jsonFields" name="AnalyticsByProvider.csv" type="csv" :escapeCsv="falseString">
                CSV
              </download-excel>
              </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              <download-excel v-if="showData" :data="tableData" :fields="jsonFields" name="AnalyticsByProvider.xls">
              Excel
            </download-excel>
            </b-dropdown-item>
        </b-dropdown>
            </div>
            </div>
</div>
  </div>
    </section>
  </template>
    <!--</div>
    </div>
    </section>
  </template>-->
  
<script>
  import { mapGetters, mapActions } from 'vuex'
  import axios from 'axios'

  export default {
    name: 'ByProviders',
    computed: mapGetters(['getRole', 'getWorkspaceId', 'getAuthHeader', 'allProjects']),
    created: function () {
      this.fetchProjects()
    },
    data () {
      return {
        messageType: null,
        tableData: null,
        showData: false,
        projectId: null,
        subHeaderMessage: '',
        isBordered: true,
        falseString: false,
        dates: [],
        locale: 'en-GB',
        // Array will be automatically processed with visualization.arrayToDataTable function
        columnsC: [
            {
                field: 'name',
                label: 'Operator',
                // width: '40',
                // numeric: true
                centered: true
            },
            {
                field: 'sum',
                label: 'No. of messages',
                centered: true
            }
        ],
        jsonFields: {
          "Operator": "name",
          "No. of messages": "sum"
        }
      }
    },
    methods: {
      ...mapActions(['fetchProjects']),
        async searchClicked () {
          if (this.dates.length !== 2 || this.projectId === null || (this.projectId === 'all' && this.messageType === null)) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Please fill in all fields.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
          } else {
            var loadingComponent = this.$buefy.loading.open()
            let startDateOriginal = this.dates[0].toLocaleDateString('en-GB')
            let endDateOriginal = this.dates[1].toLocaleDateString('en-GB')
            var startDateArray = startDateOriginal.split('/')
            var endDateArray = endDateOriginal.split('/')
            var startDate = startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0]
            var endDate = endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0]
            if (this.projectId !== 'all') {
              var projectType = this.allProjects.filter(x => x._id === this.projectId)[0].projectType
            }
            this.globalLogger(startDate, endDate)
            try {
              if (this.projectId === 'all' && this.messageType === 'sent') {
                this.globalLogger('by status')
                const response = await axios.post('https://smsgwnc.vsc.mk:8080/analytics/messagesByProvider', {
                    startDate: startDate,
                    endDate: endDate
                }, {
                    headers: { 'Authorization': this.getAuthHeader }
                })
                this.tableData = response.data
              } else if (this.projectId !== 'all' && (projectType === 'Send Messages' || projectType === 'Forward Messages')) {
                this.globalLogger('by status project')
                const response = await axios.post('https://smsgwnc.vsc.mk:8080/analytics/messagesByProviderProject/' + this.projectId, {
                    startDate: startDate,
                    endDate: endDate
                }, {
                    headers: { 'Authorization': this.getAuthHeader }
                })
                this.tableData = response.data
              } else if (this.projectId === 'all' && this.messageType === 'received') {
                this.globalLogger('received by status')
                const response = await axios.post('https://smsgwnc.vsc.mk:8080/analytics/messagesReceivedByProvider', {
                    startDate: startDate,
                    endDate: endDate
                }, {
                    headers: { 'Authorization': this.getAuthHeader }
                })
                this.tableData = response.data
              } else if (this.projectId !== 'all' && (projectType === 'Prize Pattern' || projectType === 'Prize Code' || projectType === 'Prize External')) {
                this.globalLogger('received by status project')
                const response = await axios.post('https://smsgwnc.vsc.mk:8080/analytics/messagesReceivedByProviderProject/' + this.projectId, {
                    startDate: startDate,
                    endDate: endDate
                }, {
                    headers: { 'Authorization': this.getAuthHeader }
                })
                this.tableData = response.data
              }
              loadingComponent.close()
              this.showData = true
            } catch (error) {
              loadingComponent.close()
              this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'An error occured while trying to fetch data.',
                  confirmText: 'Ok!',
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa'
              })
            }
          }
        }
    }
  }
</script>

<style>
.foo > .label { color: white }
.equalWidth {
    width: 100%;
    display: table;
    table-layout: fixed; /* optional, for equal spacing */
    border-collapse: collapse;
}
</style>
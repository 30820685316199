<template>
    <section>
      <div class="container.is-widescreen" style="padding-top:18px">
        <div v-if="this.getRole === 'Super Admin'">

            <h1 class="title" style="color:#3e8ed0">Create workspace</h1>
            <h4 class="subtitle">Add a new workspace.</h4>

                    <div class="container">
  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Name:">
            <b-input v-model="name"></b-input>
        </b-field>
    </div>
  </div>
  
  <div style="text-align: center;">
  <b-button type="is-primary" @click="addNewWorkspace">Submit</b-button>
</div>
</div>
</div>
</div>
    </section>
  </template>
  
<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'CreateWorkspace',
    data () {
      return {
        name: null
      }
    },
    computed: mapGetters(['getRole']),
    methods: {
      ...mapActions(['addWorkspace']),
      async addNewWorkspace () {
        if (this.name === null || this.name.trim() === '') {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Please enter a name.',
            confirmText: 'Ok!',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa'
          })
        } else {
          let payload = {
            name: this.name
          }
          await this.addWorkspace(payload)
          this.reset()
        }
      },
      reset () {
        this.name = null
      }
    }
  }
</script>

<style>
</style>
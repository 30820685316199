import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HoursDay from '../views/activity/HoursDay.vue'
import DaysRange from '../views/activity/DaysRange.vue'
import ByProviders from '../views/total/ByProviders.vue'
import ByType from '../views/total/ByType.vue'
import ByStatus from '../views/total/ByStatus.vue'
import CreateProject from '../views/admin/CreateProject.vue'
import CreateUser from '../views/admin/CreateUser.vue'
import CreateWorkspace from '../views/admin/CreateWorkspace.vue'
import AllUsers from '../views/admin/AllUsers.vue'
import AllProjects from '../views/admin/AllProjects.vue'
import AllWorkspaces from '../views/admin/AllWorkspaces.vue'
import SendMessage from '../views/messages/SendMessage.vue'
// import SendPersonalized from '../views/messages/SendPersonalized.vue'
import SendMessages from '../views/messages/SendMessages.vue'
import AllMessages from '../views/messages/AllMessages.vue'
import SentMessages from '../views/messages/SentMessages.vue'
import ReceivedMessages from '../views/messages/ReceivedMessages.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hoursday',
    name: 'hours_day',
    component: HoursDay,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/daysrange',
    name: 'days_range',
    component: DaysRange,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/byproviders',
    name: 'byproviders',
    component: ByProviders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bytype',
    name: 'bytype',
    component: ByType,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bystatus',
    name: 'bystatus',
    component: ByStatus,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createproject',
    name: 'create_project',
    component: CreateProject,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createuser',
    name: 'create_user',
    component: CreateUser,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createworkspace',
    name: 'create_workspace',
    component: CreateWorkspace,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/allusers',
    name: 'all_users',
    component: AllUsers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/allprojects',
    name: 'all_projects',
    component: AllProjects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/allworkspaces',
    name: 'all_workspaces',
    component: AllWorkspaces,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sendmessage',
    name: 'send_message',
    component: SendMessage,
    meta: {
      requiresAuth: true
    }
  },
  /* {
    path: '/sendpersonalizedmessage',
    name: 'send_personalized',
    component: SendPersonalized,
    meta: {
      requiresAuth: true
    }
  }, */
  {
    path: '/sendmessages',
    name: 'send_messages',
    component: SendMessages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/allmessages',
    name: 'all_messages',
    component: AllMessages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sentmessagesbyproject',
    name: 'sent_messages_by_project',
    component: SentMessages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/receivedmessagesbyproject',
    name: 'received_messages_by_project',
    component: ReceivedMessages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <div>
    <div class="top">
      <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <img src="./assets/newlogo1.png" width="135">
      <!--<div class="navbar-item" style="padding-left: 20px; font-size: larger; color: #5cb85c;">
        <p><b>eSMS web interface</b></p>
      </div>-->
    </div>

    <div id="navbarBasicExample" class="navbar-menu">

      <NavBar v-if="this.getUsername"/>
    </div>
  </nav>
    </div>
    <div v-if="this.getUsername" class="left">
      <SideBar />
    </div>
    <div class="main">
      <router-view/>
    </div>
  </div>
</template>
  
<script>
import NavBar from './components/NavBar.vue';
import SideBar from './components/SideBar.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    // AppsNew,
    // AppsView,
    NavBar,
    SideBar
  },
  computed: mapGetters(['getUsername'])
};
</script>

<style scoped>
.top {
  padding: 10px;
  position: absolute;
  left: 0;
  right: 0;
  height: 92px;
}

.left {
  position: absolute;
  left: 12px;
  top: 100px;
  bottom: 0;
  width: 250px;
}

.main {
  position: absolute;
  left: 279px;
  top: 85px;
  right: 0;
  bottom: 0;
}

.navbar
{
    border-bottom:2px solid rgb(241, 241, 241);
}
</style>
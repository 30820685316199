import axios from 'axios'
// import { ToastProgrammatic as Toast } from 'buefy'
import { LoadingProgrammatic as Loading } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'

const path = 'https://smsgwnc.vsc.mk:8080'

const state = {
    users: []
}

const getters = {
    allUsers: (state) => {
        return state.users
    }
}

const actions = {
    async fetchUsers({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/user/allUsers', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setUsers', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchUsersByWorkspaceId({ commit, rootState }, id) {
        try {
            const response = await axios.get(path + '/user/getUsersByWorkspaceId/' + id, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setUsers', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async addUser({ commit, rootState }, payload) {
        var loading = Loading.open()
        try {
            const response = await axios.post(path + '/user/addUser', {
                username: payload.username,
                password: payload.password,
                email: payload.email,
                name: payload.name,
                surname: payload.surname,
                address: payload.address,
                phone: payload.phone,
                status: payload.status,
                role: payload.role,
                workspaceId: payload.workspaceId
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('newUser', response.data)
            loading.close()
            Dialog.alert({
                title: 'Success',
                message: 'The user was successfully added.',
                confirmText: 'Ok!',
                type: 'is-success',
                hasIcon: true,
                iconPack: 'fa'
            })
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to add user.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    async editUser({commit, rootState}, payload) {
        var loading = Loading.open()
        try {
            const response = await axios.put(`${path}/user/update?id=${payload._id}`, 
            {
                username: payload.username,
                password: payload.password,
                email: payload.email,
                name: payload.name,
                surname: payload.surname,
                address: payload.address,
                phone: payload.phone,
                status: payload.status,
                role: payload.role,
                workspaceId: payload.workspaceId
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('updatedUser', response.data)
            loading.close()
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to edit user.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    async deleteUser({commit, rootState}, id) {
        var loading = Loading.open()
        try {
            await axios.delete(`${path}/user/delete?id=${id}`, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('delUser', id)
            loading.close()
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to delete user.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    }
}

const mutations = {
    setUsers: (state, users) => (state.users = users),
    newUser: (state, user) => (state.users.push(user)),
    delUser: (state, id) => (state.users = state.users.filter(usr => usr._id !== id)),
    updatedUser: (state, updUser) => {
        const index = state.users.findIndex(usr => usr._id === updUser._id)
        if (index !== -1) {
            state.users.splice(index, 1, updUser)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
<template>
    <section>
      <div v-if="getRole !== 'Analytics'" class="container.is-widescreen" style="padding-top:18px">

            <h1 class="title" style="color:#3e8ed0">Send bulk SMSa</h1>
            <h4 class="subtitle">Send a message to many users.</h4>

                    <div class="container">
                      <div style="text-align: center;padding-bottom: 20px">

<p @click="isImageModalActive = true" style="cursor: pointer;color:#3e8ed0">Click to see required file format</p>
</div>
<b-modal :width="300" v-model="isImageModalActive" >
            <p class="image" >
                <img src="../../assets/fileformat.png" >
            </p>
        </b-modal>
                        <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Project:">
          <b-select v-model="projectId" expanded>
              <option v-for="(value, key) in allProjects" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Receivers:">
            <!--<input
        type="file"
        v-on:change="addfile($event)"
        placeholder="Upload file"
        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />-->

      <b-field class="file is-primary" :class="{'has-name': !!file}">
        <b-upload v-model="file" class="file-label" accept=".xlsx, .csv, .ods" required validationMessage="Please select a file">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Click to upload (.xlxs, .csv or .ods)</span>
            </span>
            <span class="file-name" v-if="file">
                {{ file.name }}
            </span>
        </b-upload>
    </b-field>

        </b-field>
    </div>
  </div>

  <div style="text-align: center;">

        <b-field label="Message text:">
            
        <b-input v-model="messageText" type="textarea" 
        :maxlength="maxLength"></b-input>
        </b-field>
</div>

<div style="text-align: center; padding-bottom: 10px; color: red">
<p><b>{{ countString }} {{ noMessagesString }}</b></p>
</div>
  
  <div style="text-align: center;">
  <b-button type="is-primary" @click="addMess">Submit</b-button>
</div>
</div>
</div>
    </section>
  </template>
  
<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as XLSX from 'xlsx'

  export default {
    name: 'SendMessages',
    computed: mapGetters(['allProjects', 'getRole', 'getWorkspaceId']),
    created: function () {
      if (this.getRole === 'Super Admin') {
        this.fetchSendProjects()
      } else if (this.getRole !== 'Analytics') {
        this.fetchSendProjectsByWorkspaceId(this.getWorkspaceId)
      }
    },
    data () {
      return {
        messageText: '',
        receiver: null,
        projectId: null,
        file: null,
        arrayBuffer: null,
        filelist: null,
        count: null,
        noMessages: 0,
        noMessagesString: null,
        countString: null,
        maxLength: 612,
        isImageModalActive: false
      }
    },
    watch: {
      file() {
        this.addfile()
      },
      messageText () {
        this.maxLength = 612
        var extraChars = 0
        if (this.messageText) {
          for (var i = 0; i < this.messageText.length; i++) {
            var c = this.messageText[i]
            if (c === '^' || c === '{' || c === '}' || c === '\\' || c === '[' || c === ']' || c === '~' || c === '|' || c === '€') {
              extraChars += 1
            }
          }
          this.maxLength -= extraChars
          this.count = this.messageText.length + extraChars
          if (this.count > 1) {
            this.countString = this.count + ' characters,'
          } else {
            this.countString = '1 character,'
          }
          if (this.count === 0) {
            this.noMessages = 0
            this.noMessagesString = ''
            this.countString = ''
          } else if (this.count <= 160 ) {
            this.noMessages = 1
            this.noMessagesString = '1 message'
          } else if (this.count > 160 && this.count <= 320) {
            this.noMessages = 2
            this.noMessagesString = '2 messages'
          } else if (this.count > 320 && this.count <= 480) {
            this.noMessages = 3
            this.noMessagesString = '3 messages'
          } else {
            this.noMessages = 4
            this.noMessagesString = '4 messages'
          }
        } else {
          this.countString = null
          this.noMessagesString = null
        }
      }
    },
    methods: {
      ...mapActions(['fetchSendProjects', 'addMessage', 'fetchSendProjectsByWorkspaceId', 'addSingleMessage']),
      async addMess () {
        if (this.projectId === null || this.messageText === '' || this.file === null) {
          this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Please fill in all fields.',
              confirmText: 'Ok!',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa'
          })
        } else {
          if (this.filelist.length > 100) {
            let size = 100
            var subArrays = []
            for(let i = 0; i < this.filelist.length; i += size) {
              subArrays.push(this.filelist.slice(i, i+size));
            }
            this.globalLogger(subArrays, 'sub arrays')
            let payload = {
              text: this.messageText,
              projectId: this.projectId,
              size: this.noMessages,
              noOfCalls: subArrays.length
            }
            for(let j = 0; j < subArrays.length; j++) {
              payload.receiver = subArrays[j]
              payload.currentCall = j + 1
              this.globalLogger('call addd message', subArrays[j])
              await this.addMessage(payload)
            }
            this.reset()
          } else {
            let payload = {
              text: this.messageText,
              receiver: this.filelist,
              projectId: this.projectId,
              size: this.noMessages
            }
            await this.addSingleMessage(payload)
            this.reset()
          }
          
        }
      },
      reset () {
        this.messageText = null
        this.receiver = null
        this.projectId = null
        this.countString = null
        this.noMessagesString = null
      },
      addfile() {
        let fileReader = new FileReader()
        if (this.file) {
          fileReader.readAsArrayBuffer(this.file)
        }
        // eslint-disable-next-line no-unused-vars
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result
            var data = new Uint8Array(this.arrayBuffer)
            var arr = new Array()
            for (var i = 0; i != data.length; ++i) {
              arr[i] = String.fromCharCode(data[i])
            }
            var bstr = arr.join("")
            var workbook = XLSX.read(bstr, { type: "binary" })
            var first_sheet_name = workbook.SheetNames[0]
            var worksheet = workbook.Sheets[first_sheet_name]
            this.globalLogger(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
            var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true })
            this.globalLogger(arraylist, 'araylist')
            this.filelist = []
            for (let i = 0; i < arraylist.length; i++) {
              if (arraylist[i].Number !== undefined) {
                this.filelist.push(arraylist[i].Number.toString())
              } else {
                this.file = null
                this.showAlert()
                break
              }
            }
            this.globalLogger(this.filelist, 'file list')
        }
      },
      showAlert () {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Incorrect file format. Please see required file format.',
          confirmText: 'Ok!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa'
        })
      }
    }
  }
</script>

<style>
</style>
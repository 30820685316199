<template>
    <div class="modal-card" ref="element" style="width: 1100px">
        <!--style="width: auto">-->
        <header class="modal-card-head">
            <p class="modal-card-title">Edit project</p>
        </header>
        <section class="modal-card-body">
            <div class="container">
                <div class="container">
                  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
            <b-input disabled :value="projId"  style="width: 50%;"></b-input>
    </div>
    </div>
  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Project name">
            <b-input v-model="name"></b-input>
        </b-field>
    </div>
    <div class="col" v-if="getRole === 'Super Admin'">
        <b-field label="Workspace:">
          <b-select v-model="workspaceId" expanded>
              <option v-for="(value, key) in allWorkspaces" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Active from:">
                <b-datepicker
                v-model="dateFrom"
                :locale="locale"
                icon="calendar-today"
                placeholder="Click to select..."
                trap-focus>
            </b-datepicker>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Active to:">
                <b-datepicker
                v-model="dateTo"
                :locale="locale"
                icon="calendar-today"
                placeholder="Click to select..."
                trap-focus>
            </b-datepicker>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Sender">
            <b-input v-model="sender"></b-input>
        </b-field>
    </div>
  </div>

  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Project type:">
      <b-select v-model="prjType" expanded disabled>
        <option v-for="(value, key) in projectTypes" :key="key" :value="value">
                {{ value }}
              </option>
            </b-select>
    </b-field>
    </div>
    
    <div class="col">
        <b-field label="Provider:">
          <b-select v-model="outgoing" expanded>
            <option v-for="(value, key) in providers" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
    </b-field>
    </div>
    <div class="col">
      <b-field label="International?">
            <!--<b-checkbox v-model="international"></b-checkbox>-->
            <input type="checkbox" v-model="international" style="width:20px;height:20px;">
        </b-field>
    </div>
    <div v-if="international" class="col">
        <b-field label="International provider:">
          <b-select v-model="forwarding" expanded>
            <option v-for="(value, key) in providers" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
    <div class="col">
      <b-field label="Credits:">
        <b-numberinput type="is-info" controls-position="compact" controls-rounded v-model="credits" min="-1"></b-numberinput>
    </b-field>
    </div>
  </div>

  <div class="row" v-if="prjType === 'Prize Pattern'" style="padding-bottom: 20px;">
    <div class="col">
      <b-field label="Pattern:">
    <b-input v-model="pattern"
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Positive reply:">
    
    <b-input v-model="positiveReply"
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Negative reply:">
    
    <b-input v-model="negativeReply"
    ></b-input>
    </b-field>
    </div>
  </div>

    <div class="row" v-if="prjType === 'Prize Code'">
    <div class="col">
      <b-field label="Codes:" style="padding-bottom: 10px;">
    <b-input v-model="codesString" disabled
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Positive reply:">
    
    <b-input v-model="positiveReply"
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Negative reply:">
    
    <b-input v-model="negativeReply"
    ></b-input>
    </b-field>
    </div>
  </div>

  <div class="row" v-if="prjType === 'Prize External'" style="padding-bottom: 20px;">
    <div class="col">
      <b-field label="Positive reply:">
    
    <b-input v-model="positiveReply"
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Negative reply:">
    
    <b-input v-model="negativeReply"
    ></b-input>
    </b-field>
    </div>
  </div>

  <div class="row" v-if="prjType === 'Prize Code'">
    <div class="col">
        <b-field class="file is-primary" :class="{'has-name': !!file}">
        <b-upload v-model="file" class="file-label" accept=".xlsx, .csv, .ods" required validationMessage="Please select a file">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Click to upload (.xlxs, .csv or .ods)</span>
            </span>
            <span class="file-name" v-if="file">
                {{ file.name }}
            </span>
        </b-upload>
    </b-field>
    </div>
  </div>

  <div v-if="prjType === 'Prize Code'">

<p @click="isImageModalActive = true" style="cursor: pointer;color:#3e8ed0">Click to see required file format</p>
</div>
<b-modal :width="300" v-model="isImageModalActive" >
            <p class="image" >
                <img src="../assets/fileformat2.png" >
            </p>
        </b-modal>

  <div style="text-align: center;">

        <b-field label="Description">
            
        <b-input v-model="description" type="textarea" style="padding-left: 250px; padding-right: 250px;" 
        maxlength="200"></b-input>
        </b-field>
</div>
</div>
 </div>
            
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-primary" @click="editPrj" :disabled="getRole !== 'Super Admin'">Submit</b-button>
            <b-button type="is-light" @click="$emit('close')">Close</b-button>
        </footer>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as XLSX from 'xlsx'

export default {
    name: 'EditProject',
    props: ['projectToEdit'],
    computed: mapGetters(['projectTypes', 'providers', 'allWorkspaces', 'getRole']),
    created: function () {
      if (this.getRole === 'Super Admin') {
        this.fetchWorkspaces()
      }
      this.fetchProjectTypes()
      this.fetchProviders()
    },
    watch: {
      file() {
        this.addFile()
      }
    },
    data: () => {
      return {
        name: null,
        dateFrom: null,
        dateTo: null,
        prjType: null,
        sender: null,
        forwarding: null,
        outgoing: null,
        description: null,
        workspaceId: null,
        locale: 'en-GB',
        projId: null,
        pattern: null, 
        positiveReply: null,
        negativeReply: null,
        isImageModalActive: false,
        codes: null,
        codesString: null,
        file: null,
        filelist: null,
        credits: null,
        international: null
      }
    },
    mounted() {
        this.projId = 'Project ID: ' + this.projectToEdit._id
        this.name = this.projectToEdit.name
        let originalDateFrom = this.projectToEdit.activeFrom
        let array1 = originalDateFrom.split('/')
        this.dateFrom = new Date(array1[1] + '/' + array1[0] + '/' + array1[2])
        let originalDateTo = this.projectToEdit.activeTo
        let array2 = originalDateTo.split('/')
        this.dateTo = new Date(array2[1] + '/' + array2[0] + '/' + array2[2])
        this.prjType = this.projectToEdit.projectType
        this.sender = this.projectToEdit.sender
        this.forwarding = this.projectToEdit.internationalProviderId
        this.outgoing = this.projectToEdit.providerId
        this.description = this.projectToEdit.description
        this.workspaceId = this.projectToEdit.workspaceId
        this.credits = this.projectToEdit.credit
        this.international = this.projectToEdit.international
        if (this.prjType === 'Prize Pattern') {
          this.pattern = this.projectToEdit.pattern
          this.positiveReply = this.projectToEdit.positiveReply
          this.negativeReply = this.projectToEdit.negativeReply
          this.ruleId = this.projectToEdit.ruleId
        } else if (this.prjType === 'Prize Code') {
          this.codes = this.projectToEdit.codes
          this.codesString = this.codes.toString()
          this.positiveReply = this.projectToEdit.positiveReply
          this.negativeReply = this.projectToEdit.negativeReply
          this.ruleId = this.projectToEdit.ruleId
        } else if (this.prjType === 'Prize External') {
          this.positiveReply = this.projectToEdit.positiveReply
          this.negativeReply = this.projectToEdit.negativeReply
          this.ruleId = this.projectToEdit.ruleId
        }
    },
    methods: {
      ...mapActions(['editProject', 'fetchProjectTypes', 'fetchProviders', 'fetchWorkspaces',
        'editPatternProject', 'editCodeProject', 'editExternalProject']),
      editPrj () {
        if (this.getRole === 'Super Admin') {
          if (this.checkData()) {
            this.showAlert()
          } else {
            var payload = {
              _id: this.projectToEdit._id,
              name: this.name,
              activeFrom: this.dateFrom.toLocaleDateString('en-GB'),
              activeTo: this.dateTo.toLocaleDateString('en-GB'),
              projectType: this.prjType,
              sender: this.sender,
              outgoingProviderId: this.outgoing,
              description: this.description,
              workspaceId: this.workspaceId,
              credit: this.credits,
              international: this.international
            }
            if (this.international) {
              payload.forwardingProviderId = this.forwarding
            } else {
              payload.forwardingProviderId = ''
            }
            if (this.prjType === 'Send Messages' || this.prjType === 'Forward Messages') {
              this.editProject(payload)
            } else if (this.prjType === 'Prize Pattern') {
              payload.pattern = this.pattern
              payload.positiveReply = this.positiveReply
              payload.negativeReply = this.negativeReply
              payload.ruleId = this.ruleId
              this.editPatternProject(payload)
            } else if (this.prjType === 'Prize Code') {
              if (this.filelist !== null) {
                payload.codes = this.filelist
              } else {
                payload.codes = this.codes
              }
              payload.positiveReply = this.positiveReply
              payload.negativeReply = this.negativeReply
              payload.ruleId = this.ruleId
              this.editCodeProject(payload)
            } else if (this.prjType === 'Prize External') {
              payload.positiveReply = this.positiveReply
              payload.negativeReply = this.negativeReply
              payload.ruleId = this.ruleId
              this.editExternalProject(payload)
            } 
            this.$emit('close')
          }
        }
      },
      showAlert () {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'All fields are required.',
          confirmText: 'Ok!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa'
        })
      },
      checkData () {
        return this.name.trim() === '' || this.description.trim() === '' || this.sender.trim() === ''
          || (this.international && this.forwarding === '')
          || (this.prjType === 'Prize Code' && (this.positiveReply.trim() === '' || this.negativeReply.trim() === ''))
          || (this.prjType === 'Prize Pattern' && (this.pattern.trim() === '' || this.positiveReply.trim() === '' || this.negativeReply.trim() === ''))
          || (this.prjType === 'Prize External' && (this.positiveReply.trim() === '' || this.negativeReply.trim() === ''))
      },
      addFile() {
        let fileReader = new FileReader()
        if (this.file) {
          fileReader.readAsArrayBuffer(this.file)
        }
        // eslint-disable-next-line no-unused-vars
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result
            var data = new Uint8Array(this.arrayBuffer)
            var arr = new Array()
            for (var i = 0; i != data.length; ++i) {
              arr[i] = String.fromCharCode(data[i])
            }
            var bstr = arr.join("")
            var workbook = XLSX.read(bstr, { type: "binary" })
            var first_sheet_name = workbook.SheetNames[0]
            var worksheet = workbook.Sheets[first_sheet_name]
            var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true })
            this.filelist = []
            for (let i = 0; i < arraylist.length; i++) {
              if (arraylist[i].Codes !== undefined) {
                this.filelist.push(arraylist[i].Codes.toString())
              } else {
                this.filelist = null
                this.file = null
                this.showCodesAlert()
                break
              }
            }
        }
      },
      showCodesAlert () {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Incorrect file format. Please see required file format.',
          confirmText: 'Ok!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa'
        })
      },
    }
}
</script>

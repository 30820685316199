<template>
    <section>
      <div class="container.is-widescreen" style="padding-top:18px">
        <div v-if="this.getRole === 'Super Admin'">

            <h1 class="title" style="color:#3e8ed0">Create project</h1>
            <h4 class="subtitle">Add a new project.</h4>  
                    <div class="container">
  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Project type:">
      <b-select v-model="prjType" expanded>
        <option v-for="(value, key) in projectTypes" :key="key" :value="value">
                {{ value }}
              </option>
            </b-select>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Workspace:">
          <b-select v-model="workspaceId" expanded>
              <option v-for="(value, key) in allWorkspaces" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Active from:">
                <b-datepicker
                v-model="dateFrom"
                :locale="locale"
                icon="calendar-today"
                placeholder="Click to select..."
                trap-focus>
            </b-datepicker>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Active to:">
                <b-datepicker
                v-model="dateTo"
                :locale="locale"
                icon="calendar-today"
                placeholder="Click to select..."
                trap-focus>
            </b-datepicker>
    </b-field>
    </div>
  </div>

  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Project name:">
            <b-input v-model="name"></b-input>
        </b-field>
    </div>
    <div class="col"> <!--v-if="prjType !== null && prjType !== 'Forward Messages'"-->
        <b-field label="Provider:">
          <b-select v-model="outgoing" expanded>
            <option v-for="(value, key) in providers" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
    </b-field>
    </div>
    <div class="col">
      <b-field label="International?">
            <!--<b-checkbox v-model="international"></b-checkbox>-->
            <input type="checkbox" v-model="international" style="width:20px;height:20px;">
        </b-field>
    </div>
    <div class="col" v-if="international"> <!--v-if="prjType !== null && prjType === 'Forward Messages'"-->
        <b-field label="International provider:">
          <b-select v-model="forwarding" expanded>
            <option v-for="(value, key) in providers" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
    <div class="col">
      <b-field label="Credits:">
        <b-numberinput type="is-info" controls-position="compact" controls-rounded v-model="credits" min="0"></b-numberinput>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Sender:">
            <b-input v-model="sender"></b-input>
        </b-field>
    </div>
  </div>

  <div class="row" v-if="prjType === 'Prize Pattern'" style="padding-bottom: 20px;">
    <div class="col">
      <b-field label="Pattern:">
    <b-input v-model="pattern"
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Positive reply:">
    
    <b-input v-model="positiveReply"
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Negative reply:">
    
    <b-input v-model="negativeReply"
    ></b-input>
    </b-field>
    </div>
  </div>

  <div class="row" v-if="prjType === 'Prize Code'">
    <div class="col">
      <b-field label="Codes:">
        <b-field class="file is-primary" :class="{'has-name': !!file}">
        <b-upload v-model="file" class="file-label" accept=".xlsx, .csv, .ods" required validationMessage="Please select a file">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Click to upload (.xlxs, .csv or .ods)</span>
            </span>
            <span class="file-name" v-if="file">
                {{ file.name }}
            </span>
        </b-upload>
    </b-field>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Positive reply:">
    
    <b-input v-model="positiveReply"
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Negative reply:">
    
    <b-input v-model="negativeReply"
    ></b-input>
    </b-field>
    </div>
  </div>

  <div class="row" v-if="prjType === 'Prize External'" style="padding-bottom: 20px;">
    <div class="col">
      <b-field label="Positive reply:">
    
    <b-input v-model="positiveReply"
    ></b-input>
    </b-field>
    </div>
    <div class="col">
      <b-field label="Negative reply:">
    
    <b-input v-model="negativeReply"
    ></b-input>
    </b-field>
    </div>
  </div>

  <div v-if="prjType === 'Prize Code'">

<p @click="isImageModalActive = true" style="cursor: pointer;color:#3e8ed0">Click to see required file format</p>
</div>
<b-modal :width="300" v-model="isImageModalActive" >
            <p class="image" >
                <img src="../../assets/fileformat2.png" >
            </p>
        </b-modal>

  <div style="text-align: center;">

        <b-field label="Project description:">
            
        <b-input v-model="description" type="textarea" style="padding-left: 250px; padding-right: 250px;" 
        maxlength="200"></b-input>
        </b-field>
</div>
  
  <div style="text-align: center;">
  <b-button type="is-primary" @click="addProj">Submit</b-button>
</div>
</div>
</div>
</div>
    </section>
  </template>
  
<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as XLSX from 'xlsx'

  export default {
    name: 'CreateProject',
    computed: mapGetters(['projectTypes', 'providers', 'allWorkspaces', 'getRole', 'allProjects']),
    created: function () {
      if (this.getRole === 'Super Admin') {
        this.fetchProjectTypes()
        this.fetchProviders()
        this.fetchWorkspaces()
        this.fetchPrizeProjects()
      }
    },
    data () {
      return {
        international: false,
        credits: 0,
        name: null,
        dateFrom: null,
        dateTo: null,
        prjType: null,
        sender: null,
        forwarding: null,
        outgoing: null,
        description: null,
        locale: 'en-GB',
        workspaceId: null,
        positiveReply: null,
        negativeReply: null,
        pattern: null,
        file: null,
        filelist: null,
        isImageModalActive: false
        // Array will be automatically processed with visualization.arrayToDataTable function
      }
    },
    watch: {
      file() {
        this.addFile()
      }
    },
    methods: {
      ...mapActions(['addProject', 'fetchProjectTypes', 'fetchProviders', 'fetchWorkspaces', 'fetchPrizeProjects', 'addProjectPattern', 'addProjectCode', 'addProjectExternal']),
      async addProj () {
        if (this.checkSuperAdmin()) {
          this.showAlert()
        } else if ((this.prjType === 'Prize Pattern' || this.prjType === 'Prize Code') && !this.checkSender()) {
          this.showSenderAlert()
        } else {
          let payload = {
            name: this.name,
            activeFrom: this.dateFrom.toLocaleDateString('en-GB'),
            activeTo: this.dateTo.toLocaleDateString('en-GB'),
            projectType: this.prjType,
            sender: this.sender,
            description: this.description,
            workspaceId: this.workspaceId,
            credit: this.credits,
            international: this.international,
            outgoingProviderId: this.outgoing
          }
          if (this.international) {
            payload.forwardingProviderId = this.forwarding
          } else {
            payload.forwardingProviderId = ''
          }
          if (this.prjType === 'Prize Pattern') {
            payload.pattern = this.pattern
            payload.positiveReply = this.positiveReply
            payload.negativeReply = this.negativeReply
            await this.addProjectPattern(payload)
          } else if (this.prjType === 'Prize Code') {
            payload.codes = this.filelist
            payload.positiveReply = this.positiveReply
            payload.negativeReply = this.negativeReply
            await this.addProjectCode(payload)
          } else if (this.prjType === 'Prize External') {
            payload.positiveReply = this.positiveReply
            payload.negativeReply = this.negativeReply
            await this.addProjectExternal(payload)
          } else {
            await this.addProject(payload)
          }
          this.reset()
        }
      },
      reset () {
        this.workspaceId = null
        this.dateFrom = null
        this.dateTo = null
        this.prjType = null
        this.sender = null
        this.name = null
        this.forwarding = null
        this.outgoing = null
        this.description = null
        this.positiveReply = null
        this.negativeReply = null
        this.file = null
        this.pattern = null
        this.credits = 0
        this.international = false
      },
      showAlert () {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Please fill in all fields.',
          confirmText: 'Ok!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa'
        })
      },
      showSenderAlert () {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'An active project with this sender already exists. Please enter another sender.',
          confirmText: 'Ok!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa'
        })
      },
      showCodesAlert () {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Incorrect file format. Please see required file format.',
          confirmText: 'Ok!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa'
        })
      },
      checkSuperAdmin () {
        return this.name === null || this.name.trim() === '' || this.dateFrom === null || this.dateTo === null
          || this.prjType === null || this.sender === null || this.workspaceId === null
          || this.sender.trim() === '' 
          || this.outgoing === null || (this.international && this.forwarding === null)
          // || (this.prjType === 'Forward Messages' && this.forwarding === null) 
          // || (this.prjType !== 'Forward Messages' && this.prjType !== null && this.outgoing === null)
          || this.description === null || this.description.trim() === ''
          || (this.prjType === 'Prize Pattern' && this.pattern === null) || this.credits < 0
          || (this.prjType === 'Prize Code' && this.file === null)
          || ((this.prjType === 'Prize Pattern' || this.prjType === 'Prize Code' || this.prjType === 'Prize External') && (this.positiveReply === null || this.negativeReply === null))
      },
      checkSender () {
        var flag = false
        var projectsWithSameSender = []
        for (let x in this.allProjects) {
          if (this.allProjects[x].sender.toLowerCase() === this.sender.toLowerCase()) {
            flag = true
            projectsWithSameSender.push(this.allProjects[x])
          }
        }
        if (!flag) { // nema takov sender
          return true
        } else {
          var startDateArray2 = this.dateFrom.toLocaleDateString('en-GB').split('/')
          var startD2 = new Date(startDateArray2[2], parseInt(startDateArray2[1]) - 1, startDateArray2[0])
          var endDateArray2 = this.dateTo.toLocaleDateString('en-GB').split('/')
          var endD2 = new Date(endDateArray2[2], parseInt(endDateArray2[1]) - 1, endDateArray2[0])
          for (let p in projectsWithSameSender) {
            var startDateArray1 = projectsWithSameSender[p].activeFrom.split('/')
            var startD1 = new Date(startDateArray1[2], parseInt(startDateArray1[1]) - 1, startDateArray1[0])
            var endDateArray1 = projectsWithSameSender[p].activeTo.split('/')
            var endD1 = new Date(endDateArray1[2], parseInt(endDateArray1[1]) - 1, endDateArray1[0])
            if ((startD1 <= startD2 && startD2 <= endD1) || (startD1 <= endD2 && endD2 <= endD1) || (startD2 < startD1 && endD1 < endD2)) {
              return false
            }
          }
          return true
        }
      },
      addFile() {
        let fileReader = new FileReader()
        if (this.file) {
          fileReader.readAsArrayBuffer(this.file)
        }
        // eslint-disable-next-line no-unused-vars
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result
            var data = new Uint8Array(this.arrayBuffer)
            var arr = new Array()
            for (var i = 0; i != data.length; ++i) {
              arr[i] = String.fromCharCode(data[i])
            }
            var bstr = arr.join("")
            var workbook = XLSX.read(bstr, { type: "binary" })
            var first_sheet_name = workbook.SheetNames[0]
            var worksheet = workbook.Sheets[first_sheet_name]
            this.globalLogger(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
            var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true })
            this.globalLogger(arraylist, 'araylist')
            this.filelist = []
            for (let i = 0; i < arraylist.length; i++) {
              if (arraylist[i].Codes !== undefined) {
                this.filelist.push(arraylist[i].Codes.toString())
              } else {
                this.file = null
                this.showCodesAlert()
                break
              }
            }
            this.globalLogger(this.filelist, 'file list')
        }
      }
    }
  }
</script>

<style>
.input[type=checkbox] {
  accent-color: red;
}
</style>